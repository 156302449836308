import { createTheme, Theme } from "@mui/material/styles"

export const aoeuPalette = {
  aoeuBlue: "#00AFD7",
  aoeuBlue2: "#007DB6",
  darkBlue: "#0076A8",
  darkGray: "#545555",
  rioGrande: "#C4D600",
  appleGreen: "#84BD00",
  observatory: "#00966C",
  tiffanyBlue: "#00B2A9",
  allPorts: "#0076A8",
  indigo: "#486CC7",
  toolbox: "#7474C1",
  purpleMountainsMajesty: "#A57FB2",
  freeSpeechMagenta: "#E45DBF",
  vividCerise: "#DF1995",
  shiraz: "#BA0C2F",
  flamingo: "#E5554F",
  mangoTango: "#EA7600",
  sunglow: "#FFC845",
  oracle: "#395056",
  athensGray: "#EBF0F3",
  white: "#ffffff",
  whiteSmoke: "#f5f5f5",
  textPrimary: "#002936",
} as const

const gray = {
  50: "#F9FAFB",
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
} as const

// add custom colors to the global theme types
declare module "@mui/material/styles/createPalette" {
  // eslint-disable-next-line no-unused-vars
  interface Palette {
    aoeu: typeof aoeuPalette
    gray: typeof gray
  }
  // eslint-disable-next-line no-unused-vars
  interface PaletteOptions {
    aoeu: typeof aoeuPalette
    gray: typeof gray
  }
}

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface,no-unused-vars
  interface DefaultTheme extends Theme {}
}

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface,no-unused-vars
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles" {
  // eslint-disable-next-line no-unused-vars
  interface BreakpointOverrides {
    xs: true // removes the `xs` breakpoint,
    xss: true
    s: true
    sm: true
    m: true
    ml: true
    l: true
    xl: true
    md: false
    lg: false
  }
  interface Theme {
    sizes: {
      contentWidth: string
    }
  }
  interface ThemeOptions {
    sizes?: {
      contentWidth?: string
    }
  }
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
export const theme = createTheme({
  palette: {
    aoeu: aoeuPalette,
    gray,
    primary: {
      main: aoeuPalette.darkBlue,
      contrastText: aoeuPalette.white,
    },
    secondary: {
      main: aoeuPalette.appleGreen,
    },
    error: {
      main: aoeuPalette.shiraz,
      contrastText: aoeuPalette.white,
    },
    warning: {
      main: aoeuPalette.sunglow,
    },
    info: {
      main: aoeuPalette.allPorts,
    },
    success: {
      main: aoeuPalette.observatory,
    },
    text: {
      primary: aoeuPalette.darkGray,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      `"proxima-nova"`,
      `"Helvetica Neue"`,
      `"Helvetica"`,
      `"sans-serif"`,
    ].join(","),
  },
  breakpoints: {
    unit: "rem",
    step: 0.5,
    values: {
      xs: 20 /* 320px ( / 16 ) */,
      xss: 26.25 /* 420px ( / 16 ) */,
      s: 32 /* 512px ( / 16 ) */,
      sm: 40 /* 640px ( / 16 ) */,
      m: 48 /* 768px ( / 16 ) */,
      ml: 60 /* 960px ( / 16 ) */,
      l: 64 /* 1024px ( / 16 ) */,
      xl: 80 /* 1280px ( / 16 ) */,
    },
  },
  spacing: (factor: number) => `${0.5 * factor}rem`,
  sizes: {
    contentWidth: "min(1440px - 1.3rem * 2, 100% - 3rem)",
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "1rem 1rem 0 1rem",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "1rem",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          padding: "1rem",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: "1px",
          marginRight: "1px",
        },
      },
    },
  },
})
