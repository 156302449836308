import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack"
import { useMemo } from "react"

type MessageHandler = (
  message: SnackbarMessage,
  options?: OptionsObject,
) => void

// there are two areas to override min-width, the first is the snackbar and the other is the content container
// this is needed to override a permant style applied by notistack which forces the minWidth to be 228px
// https://github.com/iamhosseindhv/notistack/issues/556
const defaultVariantStyleOverrides = {
  SnackbarProps: {
    style: {
      minWidth: "auto",
    },
  },
}

// required to use the basic variant
declare module "notistack" {
  interface VariantOverrides {
    basic: true
  }
}

export const useSnacks = (): {
  showError: MessageHandler
  showSuccess: MessageHandler
  showWarning: MessageHandler
  showInfo: MessageHandler
  /**
   *  Used for a basic snackbar message without the use of Alert component. <br>
   *  Pass `anchorOrigin` in the options object to change the position of the snackbar. <br>
   *  @see https://m2.material.io/components/snackbars#usage
   */
  showBasic: MessageHandler
} => {
  const { enqueueSnackbar } = useSnackbar()
  return useMemo(
    () => ({
      showError: (message: SnackbarMessage, options?: OptionsObject) =>
        message && enqueueSnackbar(message, { variant: "error", ...options }),
      showSuccess: (message: SnackbarMessage, options?: OptionsObject) =>
        message && enqueueSnackbar(message, { variant: "success", ...options }),
      showWarning: (message: SnackbarMessage, options?: OptionsObject) =>
        message && enqueueSnackbar(message, { variant: "warning", ...options }),
      showInfo: (message: SnackbarMessage, options?: OptionsObject) =>
        message && enqueueSnackbar(message, { variant: "info", ...options }),
      showBasic: (message: SnackbarMessage, options?: OptionsObject) =>
        message &&
        enqueueSnackbar(message, {
          variant: "basic",
          ...defaultVariantStyleOverrides,
          ...options,
        }),
    }),
    [enqueueSnackbar],
  )
}

export default useSnacks
