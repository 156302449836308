/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment,no-redeclare */
import { identity, orderBy, sortBy, uniq } from "lodash"
import { Children, ReactNode } from "react"
import { ImportReport } from "admin/organizations/components/ImportUsers/useImportUsersReport"
import { GOLANG_EMPTY_UUID, PRODUCT_ENTITLEMENT_STATUS } from "~/enums"
import {
  asHTTPError,
  HttpRequestError,
  SerializedRequestError,
} from "~/errors/http-request-error"
import {
  BulkUploadPayload,
  EmailPayload,
  EntitlementPayload,
  EntitlementStatus,
  OrganizationPayload,
  OrgUserPayload,
  UserDataPayload,
} from "~/services/aoeu/models"
import { RequestResponse } from "~/services/http-client"
import { isNotEmpty, withWindow } from "~/util"

export function attachToWindow(payload: Record<string, any>): void {
  withWindow(window => {
    // @ts-ignore
    Object.keys(payload).forEach(key => (window[key] = payload[key]))
  })
}

export type Error<T extends string> = {
  field: T
  error: string
}

export type ErrorResponse<
  T extends Record<string, any>,
  E = Error<keyof T extends string ? keyof T : "Must have only string keys">,
> = {
  error: string
  field_errors: {
    0: E
  } & Array<E>
}
export type ResponseOrError<T> = RequestResponse<T> | SerializedRequestError

function substringIndices(str: string, substring: string): [number, number][] {
  if (!str || !substring) return []
  return Array.from(str.matchAll(new RegExp(substring, "g")))
    .map(a => a && [a.index, a.index! + substring.length])
    .filter(identity) as [number, number][]
}

export function injectContentArray<T extends ReactNode = ReactNode>(
  str: string,
  context: Record<string, T>,
): T[] {
  const indicators = Object.keys(context) as string[]
  const indexPairs = sortBy(
    indicators
      .flatMap(i => substringIndices(str, i))
      .filter(([start]) => start !== -1),
    ([start]) => start,
  )
  if (!indexPairs.length) return [str as T]
  if (indexPairs.length === 1) {
    const [pair] = indexPairs
    const indicator = str.slice(pair[0], pair[1])
    return [
      str.slice(0, pair[0]) as T,
      (context[indicator] ?? "!!FAILED_REPLACEMENT!!") as T,
      str.slice(pair[1]) as T,
    ]
  }
  return indexPairs.reduce((accum, pair, idx) => {
    const previous = accum.length ? indexPairs[idx - 1] : [0, 0]
    const next =
      idx < indexPairs.length - 1
        ? indexPairs[idx + 1]
        : [str.length, str.length]
    const indicator = str.slice(pair[0], pair[1])
    if (previous[1] === 0) accum.push(str.slice(previous[1], pair[0]) as T)
    accum.push((context[indicator] ?? "!!FAILED_REPLACEMENT") as T)
    accum.push(str.slice(pair[1], next[0]) as T)
    return accum
  }, [] as T[])
}

export function injectContent(
  str: string,
  content: Record<string, ReactNode>,
): ReactNode {
  return Children.toArray(injectContentArray(str, content))
}

export function setFieldErrors<T>(
  setError: (field: T, error: { message: string }) => void,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  exception: any,
): null | HttpRequestError {
  const httpError = asHTTPError(exception)
  const fieldErrors = httpError.data?.field_errors as ErrorResponse<
    Record<string, any>
  >
  if (isNotEmpty(fieldErrors) && Array.isArray(fieldErrors)) {
    fieldErrors.forEach(({ field, error }) => {
      if (error) setError(field, { message: error })
    })
    return null
  }
  return httpError
}

export function getUserDisplayName({
  firstName = "",
  lastName = "",
  swap = false,
}: {
  firstName?: Optional<string, null>
  lastName?: Optional<string, null>
  swap?: boolean
}): string {
  if (swap)
    return firstName && lastName
      ? `${lastName}, ${firstName}`
      : lastName
      ? lastName
      : firstName || ""
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : lastName
    ? lastName
    : firstName || ""
}

export function getInitials(name: string): string {
  return name
    .split(/\s/)
    .slice(0, 2)
    .map(s => s[0])
    .join("")
}

export function sleep(milliseconds = 0): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds)
  })
}

const activeStatuses = new Set<EntitlementStatus>([
  "Activated",
  "Pending",
  "Unpaid",
])
export const isActive = (ent: { productStatus: EntitlementStatus }): boolean =>
  activeStatuses.has(ent.productStatus)
export const isPRO = (ent: Named): boolean =>
  (ent.productName ?? ent.name) === "pro"
export const isFLEX = (ent: Named): boolean =>
  (ent.productName ?? ent.name) === "flex"
export const isNOW = (ent: Named): boolean =>
  (ent.productName ?? ent.name ?? "").startsWith("now")

type LicenseCount = {
  flex: number
  pro: number
  now: number
}

export function licenseCounts(arg?: OrganizationPayload): LicenseCount {
  if (!arg)
    return {
      flex: 0,
      pro: 0,
      now: 0,
    }

  return {
    flex:
      arg.license_summary
        ?.filter(ent => isActive(ent) && isFLEX(ent))
        .reduce((total, ent) => total + ent.licenseCount, 0) ?? 0,
    pro:
      arg.license_summary
        ?.filter(ent => isActive(ent) && isPRO(ent))
        .reduce((total, ent) => total + ent.licenseCount, 0) ?? 0,
    now:
      arg.license_summary
        ?.filter(ent => isActive(ent) && isNOW(ent))
        .reduce((total, ent) => total + ent.licenseCount, 0) ?? 0,
  }
}

export function getUserPrimaryEmail(user: {
  primaryEmailId: UserDataPayload["primaryEmailId"]
  edges?: {
    emails?: EmailPayload[]
  }
}): string {
  return (
    user.edges?.emails?.find(e => e.id === user.primaryEmailId)?.email ||
    "UNKNOWN"
  )
}

export type FilterableEntitlement = PickSomeRequired<
  EntitlementPayload,
  "productStatus" | "productName",
  "edges"
>

export function getUserEntitlementStatus(
  entitlements?: FilterableEntitlement[],
  filter?: (ent: FilterableEntitlement) => boolean,
): {
  pro?: EntitlementStatus
  flex?: EntitlementStatus
  now?: EntitlementStatus
} {
  if (!entitlements) return {}
  entitlements = filter ? entitlements.filter(filter) : entitlements
  return {
    pro: entitlements.find(isPRO)?.productStatus,
    flex: entitlements.find(isFLEX)?.productStatus,
    now: entitlements.find(isNOW)?.productStatus,
  }
}

export type EntitlementStatusLabel =
  | Exclude<EntitlementStatus, "Activated">
  | "Granted"
  | "Inactive"

export function transformEntitlementStatusForLabel(
  status?: EntitlementStatus,
): EntitlementStatusLabel {
  switch (status) {
    case PRODUCT_ENTITLEMENT_STATUS.ACTIVATED:
      return "Granted"
    case undefined:
      return "Inactive"
    default:
      return status
  }
}

type Named =
  | {
      name?: string
      productName?: never
    }
  | {
      name?: never
      productName?: string
    }

export function getProductLabel(
  t: Translator,
  { name, productName }: Named,
): string {
  name = name ? name : productName ? productName : ""
  switch (name) {
    case "pro":
      return t("proLearning")
    case "flex":
      return t("flexCurriculum")
  }
  const [now, season, year] = name.split("_")
  if (now.toLowerCase() === "now" && !year) return t("conference")
  return t("nowEvent", { season: t(`season.${season}`), year: `'${year}` })
}

const productPriority = ({ name, productName }: Named): number => {
  name = name ? name : productName ? productName : ""
  const [, season, year] = name.split("_")
  const nowRanking = parseInt(year, 10) * 2 - Number(season === "summer")
  // FLEX and PRO come first, then NOW are sorted by year/season
  return name === "pro" ? -1 : name === "flex" ? 0 : nowRanking
}

export function filterAndSortProducts(products: Named[]): Named[] {
  return sortBy(
    products.filter(({ name, productName }) => {
      name = name ? name : productName ? productName : ""
      const [, season] = name.split("_")
      // filter out flex_ca
      return season !== "ca"
    }),
    productPriority,
  )
}

export type EntitlementUpdateInfo =
  | "productNotIncluded"
  | "internalError"
  | "newUser"
  | "newGrant"
  | "newRevoke"
  | "noop"
  | "completeNoop"
  | "dup"
  | "unrecognized"

export type AnnotatedBulkUploadPayload = Omit<
  BulkUploadPayload,
  "entitlementUpdates"
> & {
  entitlementUpdates: (BulkUploadPayload["entitlementUpdates"][number] & {
    code: EntitlementUpdateInfo
    meta?: string
  })[]
}

export function annotateBulkUpload(
  bu: BulkUploadPayload[],
): AnnotatedBulkUploadPayload[] {
  const [users, dupes] = [
    [] as BulkUploadPayload[],
    {} as Record<number, BulkUploadPayload>,
  ]
  /* REGION: REDUPLICATION
   * This section takes entitlementUpdates describing duplicate records in the uploaded CSV
   * and creates duplicate user records in the resulting list to be rendered for user feedback. */
  for (let idx = 0; idx < bu.length; idx++) {
    if (!bu[idx].entitlementUpdates.length) {
      users.push(bu[idx])
    } else if (bu[idx].entitlementUpdates[0].info.startsWith("dup:")) {
      const partitionIdx = bu[idx].entitlementUpdates[0].index
      const duped = bu[idx].entitlementUpdates.find(
        u => u.index !== partitionIdx,
      )!
      users.push({
        ...bu[idx],
        // dup indicator which belongs to the current record
        entitlementUpdates: [{ ...bu[idx].entitlementUpdates[0] }],
      })
      dupes[duped.index] = {
        ...bu[idx],
        id: String(duped.index),
        // dup indicator which belongs to the duplicated record,
        // to be injected at the appropriate index
        entitlementUpdates: [{ ...duped }],
      }
    } else {
      users.push(bu[idx])
    }
  }
  Object.keys(dupes)
    .map(idx => parseInt(idx))
    .sort()
    .forEach(idx => {
      users.splice(idx, 0, dupes[idx])
    })

  /* ENDREGION REDUPLICATION */
  return users.map(user => ({
    ...user,
    entitlementUpdates: sortBy(
      // inserting codes with metadata for consumption during rendering
      user.entitlementUpdates.map(({ info, ...e }) => {
        let meta: Optional<string>, code: EntitlementUpdateInfo
        switch (info) {
          case "productNotIncluded":
          case "internalError":
          case "newUser":
          case "newGrant":
          case "newRevoke":
          case "noop":
            code = info
            break
          default:
            if (info.startsWith("dup:")) {
              code = "dup"
              meta = info.slice(4)
            } else {
              code = "unrecognized"
            }
        }
        return {
          ...e,
          info,
          code,
          meta,
        }
      }),
      // prioritize more interesting updates so users are more likely to see them
      ({ code }) => {
        switch (code) {
          case "noop":
            return 1
          case "newGrant":
          case "newUser":
          case "newRevoke":
            return 0
          default:
            return -1
        }
      },
    ),
  }))
}

export const DEFAULT_HEADERS = [
  "Email",
  "First Name",
  "Last Name",
  "PRO",
  "FLEX",
]
export const errorStatusOps = new Set<EntitlementUpdateInfo>([
  "productNotIncluded",
  "internalError",
  "dup",
])

// TODO: this is the legacy error report csv function
export function errorCSV(
  eu: AnnotatedBulkUploadPayload[],
  t: Translator,
): string {
  return toCSV(
    [DEFAULT_HEADERS.slice(0, 3).concat(["Errors"])].concat(
      eu.map(
        ({
          firstName,
          lastName,
          edges,
          primaryEmailId,
          entitlementUpdates,
        }) => {
          const errors = entitlementUpdates.filter(e =>
            errorStatusOps.has(e.code),
          )
          return [
            getUserPrimaryEmail({ primaryEmailId, edges }),
            firstName,
            lastName,
            // @ts-ignore
            errors.map(e => t(e.code, e)).join(", "),
          ]
        },
      ),
    ),
  )
}

export function userCSV(
  users: OrgUserPayload[],
  organization: OrganizationPayload,
  ignoredColumns: string[] = [],
): string {
  const indicatedProducts = uniq(
    filterAndSortProducts(
      users
        .flatMap(user => user?.edges?.entitlement ?? [])
        .filter(v => !!v) as EntitlementPayload[],
    ).map(e => e.productName!),
  )

  const userProductMap = users.reduce((accum, user) => {
    accum[user.id] =
      user?.edges?.entitlement?.reduce((bccum, ent) => {
        if (
          !ent.edges?.organization?.id ||
          ent.edges?.organization?.id !== organization.id
        ) {
          return bccum
        }
        bccum[ent.productName] = formatProductStatus(ent.productStatus)
        return bccum
      }, {} as Record<string, string>) ?? {}

    return accum
  }, {} as Record<string, Record<string, string>>)

  const headers = DEFAULT_HEADERS.slice(0, 3)
    .concat(indicatedProducts.map(formatProductForHeader))
    .concat(["Last Login"])
    .filter(header => !shouldColumnBeExcluded(header, ignoredColumns))

  const userRows: string[][] = orderBy(users, ["lastName", "firstName"]).map(
    user => {
      const products = indicatedProducts.reduce((accum, productName) => {
        if (
          !shouldColumnBeExcluded(
            formatProductForHeader(productName),
            ignoredColumns,
          )
        ) {
          accum.push(userProductMap[user.id][productName] ?? "")
        }

        return accum
      }, [] as string[])

      return [
        getUserPrimaryEmail(user),
        user.firstName,
        user.lastName,
        ...products,
        ...(shouldColumnBeExcluded("Last Login", ignoredColumns)
          ? []
          : [user.lastLoggedIn ?? ""]),
      ]
    },
  )

  const userTable: string[][] = [headers, ...userRows]

  return toCSV(userTable)
}

function formatProductStatus(
  productStatus:
    | "Activated"
    | "Pending"
    | "Revoked"
    | "Expired"
    | "Canceled"
    | "Unpaid",
): string {
  const map = {
    Activated: "Granted",
    Revoked: "Revoked",
    Pending: "Pending",
    Expired: "Expired",
    Canceled: "Canceled",
    Unpaid: "Unpaid",
  }

  return map[productStatus] ?? productStatus
}

function shouldColumnBeExcluded(column: string, excludedColumns: string[]) {
  if (column.toLowerCase().startsWith("now")) {
    if (excludedColumns.includes("NOW") || excludedColumns.includes("now")) {
      return true
    }
  }

  return excludedColumns.includes(column)
}

function formatProductForHeader(productName: string) {
  switch (productName) {
    case "flex":
    case "pro":
      return productName.toUpperCase()
  }

  const [, season, year] = productName.split("_")

  return `NOW ${season.slice(0, 1).toUpperCase()}${season.slice(1)} ${year}`
}

export function toCSV(table: string[][]): string {
  return table
    .map(column =>
      column
        .map(v => {
          v = String(v).replace(/"/g, '""')
          return v.includes(",") ? `"${v}"` : v
        })
        .join(","),
    )
    .join("\n")
}

export function removeEmptyUUID(str?: string | null): Optional<string> {
  if (str !== GOLANG_EMPTY_UUID) return str ?? undefined
  return undefined
}

export function reportErrorsCsv(
  errors: ImportReport["usersWithErrors"],
): string {
  return toCSV(
    [["Email", "Full Name", "Flex Status", "Pro Status", "Errors"]].concat(
      errors.map(
        ({
          email,
          fullName,
          flexEntitlementStatus,
          proEntitlementStatus,
          errors,
        }) => {
          const formattedFlexStatus =
            flexEntitlementStatus === "noop" ? "" : flexEntitlementStatus
          const formattedProEntitlementStatus =
            proEntitlementStatus === "noop" ? "" : proEntitlementStatus
          return [
            email,
            fullName,
            formattedFlexStatus,
            formattedProEntitlementStatus,
            errors.join(", "),
          ]
        },
      ),
    ),
  )
}

export function isFileTypeExcel(file: File): boolean {
  return (
    file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    file.type === "application/vnd.ms-excel"
  )
}

export async function convertExcelFileToCsv(file: File): Promise<string> {
  const { read, utils } = await import("xlsx")

  const arrayBuffer = await file.arrayBuffer()
  const workbook = read(arrayBuffer, { type: "array" })
  const firstSheetName = workbook.SheetNames[0]
  const worksheet = workbook.Sheets[firstSheetName]
  const csv = utils.sheet_to_csv(worksheet)

  return csv
}

export function removeEmptyCsvRows(csvString: string): string {
  const rows = csvString.split("\n")
  const newRows = rows.filter(row => {
    const values = row.split(",")
    return values.some(value => value != null && value.trim() !== "")
  })

  return newRows.join("\n")
}

export function transformCsvStringToFile(
  csvString: string,
  fileName?: string,
): File {
  const blob = new Blob([csvString], { type: "text/csv" })
  const file = new File([blob], fileName || "default.csv", { type: "text/csv" })

  return file
}

export function hasValidCsvHeaders(
  csvText: string,
  expectedHeaders: string[],
): boolean {
  const rows = csvText.split("\n").map(row => row.trim())
  const headers = rows[0].split(",")

  // Check if the expected headers are present and in the correct order
  for (let i = 0; i < expectedHeaders.length; i++) {
    if (headers[i] !== expectedHeaders[i]) {
      return false
    }
  }

  return true
}
