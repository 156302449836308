import { Static, TSchema, Type } from "@sinclair/typebox"
import {
  FLEXAssetPayloadBox,
  FLEXAssetTypeBox,
  OrganizationPayloadBox,
} from "~/services/aoeu/models"
// Structure of a single My Class Unit without any Asset data
export type FLEXBaseUnitPayload = Static<typeof FLEXBaseUnitPayloadBox>
// Structure of a single My Class without any unit data
export type FLEXBaseClassPayload = Static<typeof FLEXBaseClassPayloadBox>
// Full My Classes payload
export type FLEXMyClassesPayload = Static<typeof FLEXMyClassesPayloadBox>
// Full Unit including asset data
export type FLEXUnitPayload = Static<typeof FLEXUnitPayloadBox>
// Full My Class including nested Unit and Asset data
export type FLEXClassPayload = Static<typeof FLEXClassPayloadBox>

// Generic Unit Payload
const GenericFLEXBaseUnitPayloadBox = <A extends TSchema, E extends TSchema>({
  asset,
  edges,
}: {
  asset: A
  edges: E
}) =>
  Type.Object({
    id: Type.Number({ default: -1 }),
    title: Type.String(),
    description: Type.Optional(Type.String()),
    assets: Type.Array(asset),
    assetOrder: Type.Array(Type.String()),
    edges: Type.Optional(edges),
  })

// Generic Class Payload
const GenericFLEXBaseClassPayloadBox = <T extends TSchema>({
  unit,
}: {
  unit: T
}) =>
  Type.Object({
    id: Type.Number({ default: -1 }),
    title: Type.String(),
    description: Type.Optional(Type.String()),
    unitOrder: Type.Array(Type.Number()),
    assets: Type.Array(FLEXAssetPayloadBox),
    edges: Type.Object({
      organization: Type.Optional(OrganizationPayloadBox),
      units: Type.Optional(Type.Array(unit)),
      user: Type.Optional(
        Type.Object({ firstName: Type.String(), lastName: Type.String() }),
      ),
    }),
    permissions: Type.Object({
      read: Type.Boolean(),
      write: Type.Boolean(),
      owner: Type.Boolean(),
      admin: Type.Boolean(),
    }),
  })

export const FLEXBaseClassPayloadBox = GenericFLEXBaseClassPayloadBox({
  unit: Type.Object({ id: Type.Number(), title: Type.String() }),
})
export const FLEXBaseUnitPayloadBox = GenericFLEXBaseUnitPayloadBox({
  asset: Type.Object({ id: Type.String(), type: FLEXAssetTypeBox }),
  edges: Type.Object({}),
})
export const FLEXUnitPayloadBox = GenericFLEXBaseUnitPayloadBox({
  asset: FLEXAssetPayloadBox,
  edges: Type.Object({
    ["class"]: Type.Optional(FLEXBaseClassPayloadBox),
  }),
})
export const FLEXClassPayloadBox = GenericFLEXBaseClassPayloadBox({
  unit: FLEXUnitPayloadBox,
})

export const FLEXMyClassesPayloadBox = Type.Object({
  my_units: Type.Array(FLEXBaseUnitPayloadBox),
  my_classes: Type.Array(FLEXClassPayloadBox),
  district_classes: Type.Array(FLEXClassPayloadBox),
})
