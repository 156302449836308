/* eslint-disable @typescript-eslint/no-explicit-any,no-redeclare */
import { TIME } from "~/enums"

export function withWindow<T>(fn: (arg: typeof window) => T): T | undefined {
  if (
    typeof window !== "undefined" &&
    typeof fn !== "undefined" &&
    typeof fn === "function"
  ) {
    return fn(window)
  }
}

export function withDocument<T>(
  fn: (arg: typeof window.document) => T,
): T | undefined {
  return withWindow(window => {
    return fn(window.document)
  })
}

export function withNavigator<T>(
  fn: (arg: typeof window.navigator) => T,
): T | undefined {
  return withWindow(window => {
    return fn(window.navigator)
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isEmpty = (target: any): boolean => {
  if (target === undefined || target === null || target === "") return true
  if (Array.isArray(target)) {
    return target.length === 0
  } else if (
    typeof target === "object" &&
    Object.getPrototypeOf(target) === Object.prototype
  ) {
    return !Object.keys(target).length
  }
  return false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isNotEmpty = (target: any): boolean => !isEmpty(target)

export function isValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(Number(date))
}

export async function writeToClipboard<T extends string>(
  content: T,
): Promise<void> {
  withNavigator(async navigator => {
    if (!navigator.clipboard) {
      return
    }

    await navigator.clipboard.writeText(content)
  })
}

export function asyncFnRandomResponse(
  resolveWith: PromiseLike<void>,
  rejectWith: PromiseLike<void>,
): PromiseLike<void> {
  const randomTimeout = Math.floor(Math.random() * (5000 - 1000) + 1000)
  const randomSuccessFail = Math.random() < 0.5

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (randomSuccessFail) {
        resolve(resolveWith)
      } else {
        reject(rejectWith)
      }
    }, randomTimeout)
  })
}

export function simulateLatency(
  resolveWith: PromiseLike<void>,
): PromiseLike<void> {
  const randomTimeout = Math.floor(Math.random() * (5000 - 1000) + 1000)

  return new Promise(resolve => {
    setTimeout(() => resolve(resolveWith), randomTimeout)
  })
}

export function getNewAuthenticationRefreshDelay(): Date {
  return new Date(Date.now() + TIME.ONE_HOUR)
}

/**
 * Strip any instance of leading and trailing character from the provided string
 * @param str string to clean
 * @param c character to strip
 */
export function strip(str: string, c = " "): string {
  let [start, end] = [0, str.length]
  while (str[start] === c) start++
  while (str[end - 1] === c) end--
  return str.slice(start, end)
}

/**
 * Strip any instance of trailing character from the provided string
 * @param str string to clean
 * @param c character to strip
 */
export function rstrip(str: string, c = " "): string {
  let end = str.length
  while (str[end - 1] === c) end--
  return str.slice(0, end)
}

/**
 * Strip any instance of leading character from the provided string
 * @param str string to clean
 * @param c character to strip
 */
export function lstrip(str: string, c = " "): string {
  let start = 0
  while (str[start] === c) start++
  return str.slice(start, str.length)
}

/**
 * Generate an array of n integers from start incremented by step
 * @param {number} n length of resulting range
 * @param {number} start first number of range
 * @param {number} step interval between each number
 * @returns {number[]}
 *
 * @example
 * ```ts
 * range(3) === [0, 1, 2]
 * range(3, -1) === [-1, 0, 1]
 * range(3, -1, -1) === [-1, -2, -3]
 * ```
 */
export function range(n: number, start = 0, step = 1): number[] {
  const arr = Array(n)
  for (let i = 0; i < n; i++) {
    arr[i] = start
    start += step
  }
  return arr
}

/**
 * Remove trailing, leading, and extra internal whitespace from a string
 * @param {string} str string to normalize
 * @return {string}
 */
export function normalizeWhitespace(str: string): string {
  return str
    .trim() // remove leading and trailing whitespace
    .split(/\s+/) // split on any instance of one or more whitespace characters
    .join(" ") // join split array back into single word separated each by one space
}

/**
 * Generate a random integer in the interval [lower, upper)
 * @param {number} upper upper bound of the interval of possible numbers, exclusive
 * @param {number} lower lower bound of the interval of possible numbers, inclusive
 * @returns {number} return random integer in the interval
 */
export function random(upper: number, lower = 0): number {
  return Math.floor(Math.random() * (upper - lower) + lower)
}

/**
 * Pick a random element from the given array
 * @param {T[]} arr
 * @returns {T}
 */
export function randomChoice<T>(arr: T[]): T {
  return arr[random(arr.length)]
}

/**
 * Pick n random elements from the given array
 * @param {T[]} arr
 * @param {number} n
 * @param {boolean} replacement whether items can be duplicated in sample
 * @returns {T[]}
 */
export function sample<T>(arr: T[], n: number, replacement = true): T[] {
  if (replacement)
    return Array(n)
      .fill(0)
      .map(_ => randomChoice(arr))
  if (n > arr.length)
    throw new Error("Not enough items to sample without replacement.")
  const used = new Set<number>([])
  return Array(n)
    .fill(0)
    .reduce((accum, _) => {
      let candidateIdx = random(arr.length - 1)
      while (used.has(candidateIdx)) {
        candidateIdx = random(arr.length - 1)
      }
      used.add(candidateIdx)
      accum.push(arr[candidateIdx])
      return accum
    }, [] as T[])
}

/**
 * Log and return the argument, useful for log debugging without
 * making major modifications
 * @param {T} arg
 * @param logMethod function to log with
 * @returns {T}
 */
export function passthroughLog<T>(arg: T, logMethod = console.debug): T {
  logMethod(arg)
  return arg
}

export function clientSide(active = true): boolean {
  return (typeof window === "undefined") !== active
}

/**
 * @deprecated Read on for more information 👇
 *
 * At the time of this writing, the following method have landed in baseline ECMAScript
 * and have been implemented in the latest versions of Node.js and modern browsers:
 *
 * - [Set.prototype.union](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/union)
 * - [Set.prototype.intersection](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/intersection)
 * - [Set.prototype.difference](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/difference)
 *
 * However, since all methods are not yet available in node 18, we will continue to use
 * the EnhancedSet class until we can polyfill or update the node version.
 *
 * Methods have been renamed `EnhancedSet.custom*` to avoid conflicts with the native methods as
 * TypeScript has already provided type declarations.
 */
export class EnhancedSet<T extends number | string> extends Set<T> {
  private readonly array: T[] = []

  constructor(items: EnhancedSet<T> | T[] = []) {
    const arr = (Array.isArray(items) ? items : items.array) ?? []
    super(arr)
    this.array = arr
  }

  add(item: T): this {
    !this.has(item) && this.array && this.array.push(item)
    super.add(item)
    return this
  }

  customUnion(other: EnhancedSet<T>): EnhancedSet<T> {
    return new EnhancedSet(this.array.concat(other.array))
  }

  customIntersection(other: EnhancedSet<T>): EnhancedSet<T> {
    return new EnhancedSet(this.array.filter(item => other.has(item)))
  }

  customDifference(other: EnhancedSet<T>): EnhancedSet<T> {
    return new EnhancedSet(this.array.filter(item => !other.has(item)))
  }
}
