// eslint-disable-next-line ban-package-import/ban-package-import
import { NextRouter, useRouter as defaultUseRouter } from "next/router"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useAuth } from "~/hooks/use-auth"
import { genPageViewTracker, hubspotTracking } from "~/util/tracking"

export type TrackingRouter = NextRouter
// banning internal use of next/router in favor of our built-in tracking router
declare module "next/router" {
  export function useRouter(): never
}

export const useRouter = (): TrackingRouter => {
  const router = defaultUseRouter() as NextRouter
  const auth = useAuth()

  // function must be named; router.events.off cannot unsubscribe anonymous functions
  const pageViewTracker = genPageViewTracker(auth.userData?.id)

  useEffect(() => {
    router.events?.on("routeChangeComplete", hubspotTracking)
    router.events?.on("routeChangeComplete", pageViewTracker)

    // every time dependencies change or component unmounts, unsubscribe
    return () => {
      router.events?.off("routeChangeComplete", hubspotTracking)
      router.events?.off("routeChangeComplete", pageViewTracker)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, auth.userData?.id])
  return router
}

export const useLoading = (
  router: NextRouter,
  predicate?: (current: string, next: string) => boolean,
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    const current = new URL(router.asPath, window.location.origin).pathname

    function indicateLoading(url: string) {
      if (!predicate) return setIsLoading(true)
      const next = new URL(url, window.location.origin).pathname
      if (predicate(current, next)) setIsLoading(true)
    }
    function indicateLoadingDone() {
      setIsLoading(false)
    }
    router.events.on("routeChangeStart", indicateLoading)
    router.events.on("routeChangeComplete", indicateLoadingDone)
    router.events.on("routeChangeError", indicateLoadingDone)
    return () => {
      router.events.off("routeChangeStart", indicateLoading)
      router.events.off("routeChangeComplete", indicateLoadingDone)
      router.events.off("routeChangeError", indicateLoadingDone)
    }
  }, [router])
  return [isLoading, setIsLoading]
}
